/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import { ApiRequestOptions } from "../core/ApiRequestOptions";
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
import type { ContractDetailsResponse } from "../models/ContractDetailsResponse";
import type { ContractListResponseItem } from "../models/ContractListResponseItem";
import type { ContractRequest } from "../models/ContractRequest";
import type { EventContractContractCreated } from "../models/EventContractContractCreated";
import type { EventContractContractDeleted } from "../models/EventContractContractDeleted";
import type { EventContractContractEvent } from "../models/EventContractContractEvent";
import type { EventContractContractUpdated } from "../models/EventContractContractUpdated";

export class ContractService {
  /**
   * Add target
   * Add account as target for a contract
   * @returns ContractDetailsResponse OK
   * @throws ApiError
   */
  public static addTarget(
    {
      contractId,
      target,
    }: {
      contractId: string;
      target: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<ContractDetailsResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/contracts/{contractId}/targets",
      path: {
        contractId: contractId,
      },
      query: {
        target: target,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * Delete target
   * Delete target from a contract
   * @returns ContractDetailsResponse OK
   * @throws ApiError
   */
  public static deleteTarget(
    {
      contractId,
      target,
    }: {
      contractId: string;
      target: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<ContractDetailsResponse> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/contracts/{contractId}/targets",
      path: {
        contractId: contractId,
      },
      query: {
        target: target,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * Get Contract
   * Get Contract Details
   * @returns EventContractContractEvent OK
   * @throws ApiError
   */
  public static getContractEvents1(
    {
      contractId,
    }: {
      contractId: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<Array<EventContractContractEvent>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/contracts/{contractId}",
      path: {
        contractId: contractId,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * Update contract
   * Update contract and return it
   * @returns EventContractContractUpdated OK
   * @throws ApiError
   */
  public static updateContractAndGetEvents1(
    {
      contractId,
      requestBody,
    }: {
      contractId: string;
      requestBody: ContractRequest;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<Array<EventContractContractUpdated>> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/contracts/{contractId}",
      path: {
        contractId: contractId,
      },
      body: requestBody,
      mediaType: "application/json",
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * Delete contract
   * Delete contract
   * @returns EventContractContractDeleted OK
   * @throws ApiError
   */
  public static deleteContractAndGetEvents(
    {
      contractId,
    }: {
      contractId: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<Array<EventContractContractDeleted>> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/contracts/{contractId}",
      path: {
        contractId: contractId,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * List contracts
   * List all contracts
   * @returns ContractListResponseItem OK
   * @throws ApiError
   */
  public static listContracts(
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<Array<ContractListResponseItem>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/contracts",
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * Create contract
   * Create contract and return it
   * @returns EventContractContractCreated Created
   * @throws ApiError
   */
  public static createContractAndGetEvents1(
    {
      requestBody,
    }: {
      requestBody: ContractRequest;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<Array<EventContractContractCreated>> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/contracts",
      body: requestBody,
      mediaType: "application/json",
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }
}
