import { useNavigate, useParams } from "react-router-dom";

import { Asset, ProductResponseData } from "@web/sherlock";
import { Heading, Loading, Paragraph } from "@web/ui";
import { formatISODateDurationAsNumberOfDays, imagor } from "@web/utils";

import { BackActionHeader } from "src/components/BackActionHeader";
import { DigitalAssetsWrapper } from "src/components/DigitalAsset";
import { Layout } from "src/components/Layout";
import { SecondaryImagesWrapper } from "src/components/SecondaryImagesWrapper";
import { filterOutImagesAssets, filterOutNonImagesAssets } from "src/utils";

import imagePlaceholder from "../../components/icons/image_placeholder.png";
import { useProductDetailsQuery } from "../CatalogsPage/api";

const ProductAttribute = ({
  label,
  value,
  description,
}: {
  label: string;
  value?: string;
  description?: string;
}) => (
  <div className="flex flex-col mb-4">
    <b className="mb-1">{label}</b>
    <div className="bg-neutral_100 p-2 rounded-md">{value || "-"}</div>
    <Paragraph size="300" className="mt-1">
      {description || ""}
    </Paragraph>
  </div>
);

const ProductDetails = ({
  productDetails,
  productId,
}: {
  productDetails: ProductResponseData | undefined;
  productId: string;
}) => (
  <div className="p-4">
    <div className="flex mb-6 space-x-5">
      <img
        alt="product image"
        src={
          productDetails?.assets && filterOutNonImagesAssets(productDetails.assets).length > 0
            ? imagor(filterOutNonImagesAssets(productDetails.assets)[0].url || "")
            : imagePlaceholder
        }
        width={256}
        height={256}
        className="border-1 rounded-2xl mb-4 object-contain w-[256px] h-[256px]"
        data-testid="productDetailsPage_image"
      />
      <Heading size="200" weight="heavy" data-testid="productDetailsPage_name">
        {productDetails?.name}
      </Heading>
    </div>
    {productDetails?.assets &&
      filterOutNonImagesAssets(productDetails.assets.slice(1)).length > 0 && (
        <SecondaryImagesWrapper
          secondaryImages={filterOutNonImagesAssets(productDetails.assets).slice(1)}
        />
      )}
    <div className="mb-6">
      <Paragraph size="100" className="mb-4" data-testid="productDetailsPage_shortDescription">
        <b>Short Description</b>
        <div className="bg-neutral_100 p-2 rounded-md">{productDetails?.name}</div>
        <Paragraph size="300" className="mt-1">
          Preferably the following combination: Brand + Description + Net Content of the Article.
          Max. 60 Characters
        </Paragraph>
      </Paragraph>
      <Paragraph size="100" data-testid="productDetailsPage_longDescription">
        <b>Long Description</b>
        <div className="bg-neutral_100 p-2 rounded-md">{productDetails?.description}</div>
        <Paragraph size="300" className="mt-1">
          Extended and detailed description of the Article. Max. 300 Characters
        </Paragraph>
      </Paragraph>
    </div>
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
      <ProductAttribute
        data-testid="productDetailsPage_leadTime"
        label="Lead Time"
        value={formatISODateDurationAsNumberOfDays(productDetails?.leadTime as string)}
        description="The estimated time (in calendar days) needed to deliver the Article."
      />
      <ProductAttribute
        data-testid="productDetailsPage_unitOfMeasure"
        label="Unit of Measure"
        value={productDetails?.displayUnit}
        description="The Unit in which the Article can be ordered and invoiced."
      />
      <ProductAttribute
        data-testid="productDetailsPage_minimumOrderQuantity"
        label="Minimum Order Quantity"
        value={productDetails?.defaultOrderQuantity?.minimumOrderQuantity?.toString()}
        description="The Minimum number of Articles that must be ordered (per Unit of Measure)."
      />
      <ProductAttribute
        data-testid="productDetailsPage_quantitySteps"
        label="Quantity Steps"
        value={productDetails?.defaultOrderQuantity?.orderQuantityIncrement?.toString()}
        description="The number of Articles the order increases by with each step (per Unit of Measure)."
      />
      <ProductAttribute
        data-testid="productDetailsPage_quantityPerArticle"
        label="Quantity Per Article"
        value={productDetails?.quantityPerArticle}
        description="The amount of smaller units inside the Article unit. E.g.: A carton of Soda that contains 24 cans inside.
        Quantity per Article = 24"
      />
      <ProductAttribute
        data-testid="productDetailsPage_quantityPerArticleUom"
        label="Quantity Per Article UoM"
        value={
          productDetails?.attributes?.find((attr) => attr.name === "quantityPerArticleUom")?.value
        }
        description="The amount of smaller units inside the Article unit. E.g.: A carton of Soda that contains 24 cans inside.
        Quantity per Article Unit of Measure = CAN"
      />
    </div>
    {filterOutImagesAssets(productDetails?.assets || []).length > 0 && (
      <div className="mb-6">
        <Heading size="300" className="font-semibold mb-3 mt-4">
          Attachments
        </Heading>
        <DigitalAssetsWrapper
          assets={filterOutImagesAssets(productDetails?.assets || [])
            .filter((asset) => asset.url)
            .map((asset: Asset) => ({
              name: asset.filename || "",
              size: asset.fileSize || 0,
              downloadLink: asset.url || "",
              attachmentId: asset.url || "",
            }))}
          showMoreLabel={`Show More Attachments`}
          showLessLabel="Show Less Attachments"
          showLimit={6}
          className="mt-2 mb-3"
        />
      </div>
    )}
    <div className="mb-6">
      <Heading size="300" className="mb-4">
        General Information
      </Heading>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        <ProductAttribute
          data-testid="productDetailsPage_netContent"
          label="Net Content"
          value={productDetails?.netContent}
          description="The Net Content of the Article per Unit of Measure."
        />
        <ProductAttribute
          data-testid="productDetailsPage_netContentUom"
          label="Net Content UoM"
          value={productDetails?.attributes?.find((attr) => attr.name === "netContentUom")?.value}
          description="The Net Content of the Article per Unit of Measure."
        />
        <ProductAttribute
          data-testid="productDetailsPage_countryOfOrigin"
          label="Country of origin"
          value={productDetails?.countryOfOrigin}
          description="The Country in which the Article was produced."
        />
        <ProductAttribute
          data-testid="productDetailsPage_impa"
          label="IMPA Code"
          value={productDetails?.identifiers?.impa}
        />
        <ProductAttribute
          data-testid="productDetailsPage_brand"
          label="Brand name"
          value={productDetails?.identifiers?.brand}
          description="The brand name of the article."
        />
        <ProductAttribute
          data-testid="productDetailsPage_manufacturerName"
          label="Manufacturer Name"
          value={productDetails?.manufacturer}
        />
        <ProductAttribute
          data-testid="productDetailsPage_mpn"
          label="Manufacturer's Part Number"
          value={productDetails?.identifiers?.mpn}
        />
        <ProductAttribute
          data-testid="productDetailsPage_productId"
          label="Supplier Article Code"
          value={productId}
          description="The code identifying the specific article provided by the supplier"
        />
        <ProductAttribute
          data-testid="productDetailsPage_buyerArticleCode"
          label="Buyer Article Code"
          value={
            productDetails?.attributes?.find((attr) => attr.name === "buyerArticleCode")?.value
          }
          description=""
        />
        <ProductAttribute
          data-testid="productDetailsPage_gtin"
          label="GTIN"
          value={productDetails?.identifiers?.gtin}
          description="Global Trade Item Number used by manufacturer. Can be any code of the GTIN family, i.e. EAN or UPC code."
        />
      </div>
    </div>
    <div className="mb-6">
      <Heading size="300" className="mb-4">
        Storage Information
      </Heading>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        <ProductAttribute
          data-testid="productDetailsPage_shelfLife"
          label="Shelf Life"
          value={formatISODateDurationAsNumberOfDays(
            productDetails?.storageInformation?.shelfLife as string
          )}
          description="The Article life span from date of production (in calendar days)."
        />
        <ProductAttribute
          data-testid="productDetailsPage_storageInformationRequirements"
          label="Storage Requirements"
          value={productDetails?.storageInformation?.information}
          description=""
        />
      </div>
    </div>
    <div className="mb-6">
      <Heading size="300" className="mb-4">
        Care and Use
      </Heading>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        <ProductAttribute
          data-testid="productDetailsPage_allergens"
          label="Allergens"
          value={productDetails?.allergens?.join(", ")}
          description="If the Article contains any allergens, traces of allergens or might contain traces of allergens please list them separated by a comma."
        />
        <ProductAttribute
          data-testid="productDetailsPage_alcoholPercentage"
          label="Alcohol Percentage"
          value={
            productDetails?.attributes?.find((attr) => attr.name === "alcoholPercentage")?.value
          }
          description="The numeric alcohol content of the Article"
        />
        <ProductAttribute
          data-testid="productDetailsPage_unCodeHazardClass"
          label="UN Code (Hazard Class)"
          value={productDetails?.hazardousMaterialInformation?.unNumbers?.join(", ")}
          description="The UN Code specifying hazardous substances within the Article."
        />
        <ProductAttribute
          data-testid="productDetailsPage_customsCode"
          label="Customs Code"
          value={productDetails?.customsCode}
        />
        <ProductAttribute
          data-testid="productDetailsPage_halal"
          label="Halal"
          value={productDetails?.attributes?.find((attr) => attr.name === "halal")?.value}
        />
        <ProductAttribute
          data-testid="productDetailsPage_vegan"
          label="Vegan"
          value={productDetails?.attributes?.find((attr) => attr.name === "vegan")?.value}
        />
        <ProductAttribute
          data-testid="productDetailsPage_kosher"
          label="Kosher"
          value={productDetails?.attributes?.find((attr) => attr.name === "kosher")?.value}
        />
        <ProductAttribute
          data-testid="productDetailsPage_vegetarian"
          label="Vegetarian"
          value={productDetails?.attributes?.find((attr) => attr.name === "vegetarian")?.value}
        />
      </div>
    </div>
    <div className="mb-6" data-testid="productDetailsPage_nutrition">
      <Heading size="300" className="mb-4">
        Nutrition
      </Heading>
      <Paragraph size="100" className="mb-4">
        <b>Ingredients</b>
        <div className="bg-neutral_100 p-2 rounded-md">
          {productDetails?.ingredients?.join(", ")}
        </div>
        <Paragraph size="300" className="mt-1">
          All the ingredients related to the article
        </Paragraph>
      </Paragraph>

      {productDetails?.nutritionInformation?.map((nutrition, index) => (
        <div key={index}>
          <Heading size="400" className="mb-2">
            Per {nutrition.unitOfMeasure}
          </Heading>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {nutrition.nutrients?.map((item, idx) => (
              <ProductAttribute key={idx} label={item.name || ""} value={item.value} />
            ))}
          </div>
        </div>
      ))}
    </div>
  </div>
);

export const ProductDetailsPage = () => {
  const { catalogId, productId } = useParams<{ catalogId: string; productId: string }>();
  const navigate = useNavigate();

  const {
    data: productDetails,
    isPending,
    isError,
  } = useProductDetailsQuery(catalogId as string, productId as string);

  return (
    <Layout>
      <div data-testid="productDetailsPage">
        {isPending && <Loading />}
        {!isPending && !isError && productDetails && (
          <div>
            <BackActionHeader backActionTitle="" backActionCallback={() => navigate("/catalogs")} />
            <ProductDetails
              productDetails={productDetails[0].product}
              productId={productId || ""}
            />
          </div>
        )}
        {isError && <div className="mt-10">Error while fetching product details</div>}
      </div>
    </Layout>
  );
};
