import { useQuery } from "@tanstack/react-query";

import { ProductSearchResultItem, ProductService } from "@web/sherlock";

export const PRODUCT_DETAILS_QUERY_KEY_BASE = "productDetails";

export const useProductDetailsQuery = (catalogId: string, productId: string) =>
  useQuery<ProductSearchResultItem[]>({
    queryKey: [PRODUCT_DETAILS_QUERY_KEY_BASE, catalogId, productId],
    queryFn: async () => {
      const headers = {
        Accept: "*/*",
      };
      const options = {
        headers: headers,
      };
      const response = await ProductService.supplierQuery(
        {
          catalogId: catalogId,
          search: `productId: ${productId}`,
        },
        options
      );

      return response.products || [];
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
