import { useQuery } from "@tanstack/react-query";

import { ImageMetricsResult, ProductService } from "@web/sherlock";

export const IMAGES_DATA_QUERY_KEY_BASE = "imagesData";

export const useImagesDataQuery = (
  catalogId: string,
  targetSrn: string,
  locode: string,
  queryOptions = {}
) =>
  useQuery<ImageMetricsResult>({
    queryKey: [IMAGES_DATA_QUERY_KEY_BASE, catalogId, targetSrn, locode],
    queryFn: async () => {
      const response = await ProductService.getImageMetrics({
        catalogId: catalogId,
        targetSrn: targetSrn,
        locode: locode,
      });
      return response as ImageMetricsResult;
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    ...queryOptions,
  });
