import jwtDecode from "jwt-decode";
import { useAuth } from "oidc-react";
import { PropsWithChildren, useEffect, useState } from "react";

import { OpenAPI as AttachmentsOpenAPI } from "@web/attachments";
import { OpenAPI as CuratorOpenAPI } from "@web/curator";
import { OpenAPI as SherlockOpenAPI } from "@web/sherlock";
import { OpenAPI as SopimusOpenAPI } from "@web/sopimus";
import { Loading } from "@web/ui";
import { OpenAPI as WallstreetOpenAPI } from "@web/wallstreet";
import { OpenAPI as WarehouseOpenAPI } from "@web/warehouse";

import { UnauthenticatedApp } from "../components/UnauthenticatedApp";
import { useScopeContext } from "./ScopeContext/ScopeContext";

type AuthState = "loading" | "authenticated" | "notAuthenticated";

interface JwtDecoded {
  exp: number;
  scope: string;
}

const getUserScope = (token: string) => {
  try {
    const decoded = jwtDecode(token);
    const scope = (decoded as JwtDecoded).scope.split(" ");
    return scope;
  } catch (err) {
    console.warn(err);
    return [];
  }
};

export const AuthCheckpoint = ({ children }: PropsWithChildren) => {
  const [state, setState] = useState<AuthState>("loading");
  const auth = useAuth();
  const [, dispatch] = useScopeContext();

  useEffect(() => {
    if (auth && auth.userData) {
      const handleToken = async () => {
        const token = auth.userData?.access_token;
        AttachmentsOpenAPI.TOKEN = auth.userData?.access_token;
        CuratorOpenAPI.TOKEN = auth.userData?.access_token;
        SherlockOpenAPI.TOKEN = auth.userData?.access_token;
        SopimusOpenAPI.TOKEN = auth.userData?.access_token;
        WallstreetOpenAPI.TOKEN = auth.userData?.access_token;
        WarehouseOpenAPI.TOKEN = auth.userData?.access_token;
        const scope = getUserScope(token as string);
        dispatch({ type: "setScopeAction", value: scope });
        setState("authenticated");
      };
      handleToken().catch(console.error);
    } else {
      if (auth.isLoading) {
        setState("loading");
      } else {
        setState("notAuthenticated");
      }
    }
  }, [setState, dispatch, auth.userData, auth.isLoading, auth]);

  if (state === "loading") {
    return <Loading />;
  }

  if (state === "notAuthenticated") {
    return <UnauthenticatedApp />;
  }

  return <>{children}</>;
};
