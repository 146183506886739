import { useAuth } from "oidc-react";

import { TopBar } from "./TopBar";

export const TopBarController = () => {
  const auth = useAuth();

  return (
    <>
      <TopBar
        onSignOut={() => {
          auth.signOutRedirect();
        }}
      />
    </>
  );
};
