import { Loading } from "@web/ui";

import { Layout } from "src/components/Layout";

import { useGetWarehouseCatalogsQuery } from "./api/useGetWarehouseCatalogsQuery";
import { WarehouseCatalogOverview } from "./components/WarehouseCatalogOverview";

export const DashboardPage = () => {
  const { data: warehouseCatalogs, isLoading: isLoadingWarehouseCatalogs } =
    useGetWarehouseCatalogsQuery();

  if (isLoadingWarehouseCatalogs) {
    return <Loading />;
  }

  return (
    <Layout>
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-4 mb-6">
        {warehouseCatalogs?.map((warehouseCatalog, i) => {
          return <WarehouseCatalogOverview warehouseCatalog={warehouseCatalog} key={i} />;
        })}
      </div>
    </Layout>
  );
};
