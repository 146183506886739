/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import { ApiRequestOptions } from "../core/ApiRequestOptions";
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
import type { Srn } from "../models/Srn";

export class PriceAvailabilityControllerService {
  /**
   * @returns Srn OK
   * @throws ApiError
   */
  public static availability(
    {
      location,
      target,
      catalog,
    }: {
      location?: string;
      target?: string;
      catalog?: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<Array<Srn>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/prices/availability/",
      query: {
        location: location,
        target: target,
        catalog: catalog,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }
}
