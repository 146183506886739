import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { ApiError } from "@web/attachments";
import { ImageMetricsResult, ProductService } from "@web/sherlock";

type ResponseType = ImageMetricsResult;

type ValuesType = { catalogId: string };

export const SHERLOCK_IMAGES_DATA_QUERY_KEY_BASE = "sherlockImagesData";

const getQueryKey = (catalogId: string) => [SHERLOCK_IMAGES_DATA_QUERY_KEY_BASE, catalogId];

export const useGetImagesDataQuery = (
  args?: ValuesType,
  hookOptions?: UseQueryOptions<ResponseType, ApiError>
) => {
  const { catalogId = "" } = args ?? {};

  const queryKey = getQueryKey(catalogId);

  return useQuery<ResponseType, ApiError>({
    queryKey,
    queryFn: async () => {
      const response = await ProductService.getImageMetrics({ catalogId });

      return response;
    },
    enabled: !!catalogId,
    ...hookOptions,
  });
};
