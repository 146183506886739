import { useQuery } from "@tanstack/react-query";

import { Product } from "@web/sherlock";
import { PriceAvailabilityControllerService } from "@web/wallstreet";

export const AVAILABLE_PORTS_QUERY_KEY_BASE = "availablePorts";

export const useAvailablePortsQuery = (
  token: string,
  catalogId: string,
  targetSrn: string,
  queryOptions = {}
) =>
  useQuery<Product[], Error>({
    queryKey: [AVAILABLE_PORTS_QUERY_KEY_BASE, catalogId, targetSrn],
    queryFn: async () => {
      const headers = {
        Authorization: `Bearer ${token}`,
        Accept: "*/*",
      };
      const options = {
        headers: headers,
      };
      const response = await PriceAvailabilityControllerService.availability(
        { target: targetSrn, catalog: catalogId },
        options
      );
      return response;
    },

    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    ...queryOptions,
  });
