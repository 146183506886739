import { ChevronLeftIcon } from "@heroicons/react/outline";

import { IconButton, Label } from "@web/ui";

interface Props {
  backActionTitle: string;
  backActionCallback: () => void;
}

export const BackActionHeader: React.FC<Props> = ({ backActionTitle, backActionCallback }) => {
  return (
    <div className={`flex w-full`} data-testid="backActionHeader">
      <div className="w-[1024px] flex items-center mb-5">
        <IconButton
          size="large"
          variant="secondary"
          shape="circle"
          label="Go back"
          onClick={backActionCallback}
          Icon={ChevronLeftIcon}
        />
        <div className="flex-col" onClick={backActionCallback}>
          <div className="flex align-start pl-4.5">
            <Label size="200" color="text-textIcon-blackPrimary">
              {backActionTitle}
            </Label>
          </div>
        </div>
      </div>
    </div>
  );
};
