import { ReactNode, createContext, useContext, useState } from "react";

import { ProductSearchResultItem } from "@web/sherlock";
import { OptionType } from "@web/ui";

interface CatalogContextProps {
  selectedCatalog: OptionType<string> & { srn?: string };
  setSelectedCatalog: (value: OptionType<string> & { srn?: string }) => void;
  selectedContract: OptionType<string>;
  setSelectedContract: (value: OptionType<string>) => void;
  selectedAgreement: OptionType<string>;
  setSelectedAgreement: (value: OptionType<string>) => void;
  selectedPort: OptionType<string>;
  setSelectedPort: (value: OptionType<string>) => void;
  locationCode: string;
  setLocationCode: (value: string) => void;
  productsList: ProductSearchResultItem[];
  setProductsList: (value: ProductSearchResultItem[]) => void;
  showHint: boolean;
  setShowHint: (value: boolean) => void;
  reset: () => void;
  searchInputValue: string;
  setSearchInputValue: (value: string) => void;
}

const CatalogContext = createContext<CatalogContextProps>({
  selectedCatalog: {
    value: "select-catalog",
    label: "Select catalog",
  },
  setSelectedCatalog: () => {},
  selectedContract: {
    value: "select-contract",
    label: "Select contract",
  },
  setSelectedContract: () => {},
  selectedAgreement: {
    value: "select-agreement",
    label: "Select agreement",
  },
  setSelectedPort: () => {},
  selectedPort: {
    value: "select-port",
    label: "Select port",
  },
  setSelectedAgreement: () => {},
  locationCode: "",
  setLocationCode: () => {},
  productsList: [],
  setProductsList: () => {},
  showHint: true,
  setShowHint: () => {},
  searchInputValue: "",
  setSearchInputValue: () => {},
  reset: () => {},
});

export const useCatalogContext = () => useContext(CatalogContext);

interface CatalogProviderProps {
  children: ReactNode;
}

const defaultValues = {
  selectedCatalog: {
    value: "select-catalog",
    label: "Select catalog",
  },
  selectedContract: {
    value: "select-contract",
    label: "Select contract",
  },
  selectedAgreement: {
    value: "select-agreement",
    label: "Select agreement",
  },
  selectedPort: {
    value: "select-port",
    label: "Select port",
  },
  locationCode: "",
  productsList: [],
  showHint: true,
  searchInputValue: ""
};

export const CatalogProvider = ({ children }: CatalogProviderProps) => {
  const [selectedCatalog, setSelectedCatalog] = useState<OptionType<string> & { srn?: string }>(
    defaultValues.selectedCatalog
  );
  const [selectedContract, setSelectedContract] = useState<OptionType<string>>(
    defaultValues.selectedContract
  );
  const [selectedAgreement, setSelectedAgreement] = useState<OptionType<string>>(
    defaultValues.selectedAgreement
  );
  const [selectedPort, setSelectedPort] = useState<OptionType<string>>(defaultValues.selectedPort);
  const [locationCode, setLocationCode] = useState<string>(defaultValues.locationCode);
  const [productsList, setProductsList] = useState<ProductSearchResultItem[]>(defaultValues.productsList);
  const [showHint, setShowHint] = useState<boolean>(defaultValues.showHint);
  const [searchInputValue, setSearchInputValue] = useState<string>(defaultValues.searchInputValue);

  const reset = () => {
    setSelectedCatalog(defaultValues.selectedCatalog);
    setSelectedContract(defaultValues.selectedContract);
    setSelectedAgreement(defaultValues.selectedAgreement);
    setSelectedPort(defaultValues.selectedPort);
    setLocationCode(defaultValues.locationCode);
    setProductsList(defaultValues.productsList);
    setSearchInputValue(defaultValues.searchInputValue)
    setShowHint(defaultValues.showHint);
  };

  return (
    <CatalogContext.Provider
      value={{
        selectedCatalog,
        setSelectedCatalog,
        selectedContract,
        setSelectedContract,
        selectedAgreement,
        setSelectedAgreement,
        selectedPort,
        setSelectedPort,
        locationCode,
        setLocationCode,
        productsList,
        setProductsList,
        showHint,
        setShowHint,
        searchInputValue,
        setSearchInputValue,
        reset,
      }}
    >
      {children}
    </CatalogContext.Provider>
  );
};
