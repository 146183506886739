import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

import { OpenAPI as AttachmentsOpenAPI } from "@web/attachments";
import { OpenAPI as CuratorOpenAPI } from "@web/curator";
import { OpenAPI as SherlockOpenAPI } from "@web/sherlock";
import { OpenAPI as SopimusOpenAPI } from "@web/sopimus";
import { OpenAPI as WallstreetOpenAPI } from "@web/wallstreet";
import { OpenAPI as WarehouseOpenAPI } from "@web/warehouse";

import { setAppLanguage } from "./config/i18n";
import AppProviders from "./context/Providers";
import { Routes } from "./routes";

// Do not destruct, so that browser `process` polyfill works properly. This is crucial for Storybook to work properly.
const VITE_SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN as string;
const VITE_ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT as string;
const VITE_GIT_HASH = import.meta.env.VITE_GIT_HASH as string;

Sentry.init({
  integrations: [new BrowserTracing()],
  dsn: VITE_SENTRY_DSN,
  environment: VITE_ENVIRONMENT,
  release: VITE_GIT_HASH,
});

// setup google-analytics

const apiBaseUrl = import.meta.env.VITE_API_BASE_URL;

AttachmentsOpenAPI.BASE = apiBaseUrl;
AttachmentsOpenAPI.ENCODE_PATH = encodeURIComponent;
CuratorOpenAPI.BASE = apiBaseUrl;
CuratorOpenAPI.ENCODE_PATH = encodeURIComponent;
SherlockOpenAPI.BASE = apiBaseUrl;
SherlockOpenAPI.ENCODE_PATH = encodeURIComponent;
SopimusOpenAPI.BASE = apiBaseUrl;
SopimusOpenAPI.ENCODE_PATH = encodeURIComponent;
WallstreetOpenAPI.BASE = apiBaseUrl;
WallstreetOpenAPI.ENCODE_PATH = encodeURIComponent;
WarehouseOpenAPI.BASE = apiBaseUrl;
WarehouseOpenAPI.ENCODE_PATH = encodeURIComponent;

const App = () => {
  setAppLanguage();

  return (
    <AppProviders>
      <Routes />
    </AppProviders>
  );
};

export default App;
