import { useQuery } from "@tanstack/react-query";

import { ContractListResponseItem, ContractService } from "@web/sopimus";

export const CONTRACTS_QUERY_KEY_BASE = "contracts";

export const useContractsQuery = (token: string) =>
  useQuery<ContractListResponseItem[]>({
    queryKey: [CONTRACTS_QUERY_KEY_BASE],
    queryFn: async () => {
      const headers = {
        Authorization: `Bearer ${token}`,
        Accept: "*/*",
      };
      const response = await ContractService.listContracts({ headers });

      return response;
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
