import { PropsWithChildren } from "react";

import { Sidebar } from "./Sidebar";
import { SidebarLayout } from "./SidebarLayout";
import { TopBarController } from "./TopBar";

export const Layout = ({ children }: PropsWithChildren) => {
  return (
    <SidebarLayout header={<TopBarController />} noPadding={false} sidebar={<Sidebar />}>
      {children}
    </SidebarLayout>
  );
};
