import { useQuery } from "@tanstack/react-query";

import { AgreementDetailsResponse, ContractDetailsResponse, ContractService } from "@web/sopimus";

export const AGREEMENTS_QUERY_KEY_BASE = "agreements";

export const useAgreementsQuery = (token: string, contractId: string, queryOptions = {}) =>
  useQuery<AgreementDetailsResponse>({
    queryKey: [AGREEMENTS_QUERY_KEY_BASE, contractId],
    queryFn: async () => {
      const headers = {
        Authorization: `Bearer ${token}`,
        Accept: "*/*",
      };
      const options = {
        headers: headers,
      };
      const response = (await ContractService.getContractEvents1(
        { contractId: contractId },
        options
      )) as unknown as ContractDetailsResponse;

      return response.agreements as AgreementDetailsResponse;
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    ...queryOptions,
  });
