import { useQuery } from "@tanstack/react-query";

import { Catalog, CatalogsService } from "@web/warehouse";

export const CATALOGS_QUERY_KEY_BASE = "catalogs";

export const useCatalogsQuery = () =>
  useQuery<Catalog[]>({
    queryKey: [CATALOGS_QUERY_KEY_BASE],
    queryFn: async () => {
      const response = await CatalogsService.list();
      return response;
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
