import {
  DefaultError,
  InfiniteData,
  QueryKey,
  UseInfiniteQueryOptions,
  useInfiniteQuery,
} from "@tanstack/react-query";

import { ProductSearchResult, ProductService } from "@web/sherlock";
import { getImageFilterQuery } from "src/utils";

import { ImageQualityFilter } from "../types";

export const PRODUCTS_QUERY_KEY_BASE = "products";

const buildSearchQuery = (
  search: string | undefined,
  imageQualityFilter: ImageQualityFilter = "ALL"
): string => {
  const imageFilterQuery = getImageFilterQuery(imageQualityFilter);
  const searchPart = search ? `name:${search}` : "";

  if (searchPart && imageFilterQuery) {
    return `${searchPart} AND ${imageFilterQuery}`;
  }

  return searchPart || imageFilterQuery;
};

const fetchProducts = async (
  catalogId: string,
  size: number,
  imageQualityFilter: ImageQualityFilter,
  search: string | undefined,
  pageParam: number,
  targetSrn?: string,
  locode?: string
): Promise<ProductSearchResult> => {
  const headers = {
    Accept: "*/*",
  };

  const options = {
    headers,
  };

  const searchQuery = buildSearchQuery(search, imageQualityFilter);

  const queryParameters: {
    catalogId: string;
    search?: string;
    locode?: string;
    targetSrn?: string;
    page: number;
    size: number;
  } = {
    catalogId,
    search: searchQuery,
    page: pageParam,
    size,
  };

  if (targetSrn) {
    queryParameters.targetSrn = targetSrn;
  }

  if (locode) {
    queryParameters.locode = locode;
  }

  const response = await ProductService.supplierQuery(queryParameters, options);

  return response;
};

const getNextPageParam = (lastPage: ProductSearchResult) =>
  lastPage.pageCursor?.currentPage &&
  lastPage.pageCursor?.totalPages &&
  lastPage.pageCursor.currentPage < lastPage.pageCursor.totalPages
    ? lastPage.pageCursor.currentPage + 1
    : undefined;

export const useInfiniteProductsQuery = (
  catalogId: string,
  size: number,
  imageQualityFilter: ImageQualityFilter,
  targetSrn?: string,
  locode?: string,
  search?: string,
  queryOptions: Partial<
    UseInfiniteQueryOptions<
      ProductSearchResult,
      DefaultError,
      InfiniteData<ProductSearchResult>,
      ProductSearchResult,
      QueryKey,
      number
    >
  > = {}
) =>
  useInfiniteQuery<
    ProductSearchResult,
    DefaultError,
    InfiniteData<ProductSearchResult>,
    QueryKey,
    number
  >({
    queryKey: [PRODUCTS_QUERY_KEY_BASE, catalogId, search],
    queryFn: ({ pageParam }) =>
      fetchProducts(catalogId, size, imageQualityFilter, search, pageParam, targetSrn, locode),
    initialPageParam: 1,
    getNextPageParam,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    ...queryOptions,
  });
