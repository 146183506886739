import { useNavigate } from "react-router-dom";

import { Loading } from "@web/ui";
import { Catalog } from "@web/warehouse";

import { RoutesConfig } from "src/config/routes";
import { useCatalogContext } from "src/context/CatalogContext";

import { useGetImagesDataQuery } from "../api";
import { ProductOverviewBox } from "./ProductOverviewBox";

type Props = {
  warehouseCatalog: Catalog;
};

const convertCatalogToSelectValue = (warehouseCatalog: Catalog) => {
  const { catalogId, name, srn } = warehouseCatalog ?? {};
  if (catalogId && name && srn) {
    return {
      value: catalogId,
      label: name,
      srn: srn as unknown as string,
    };
  } else {
    return { value: "select-catalog", label: "Select catalog" };
  }
};

export const WarehouseCatalogOverview = ({ warehouseCatalog }: Props) => {
  const { data: imagesData, isLoading: isLoadingImagesData } = useGetImagesDataQuery({
    catalogId: warehouseCatalog.catalogId ?? "",
  });

  const navigate = useNavigate();

  const { setSelectedCatalog, reset: resetCatalogContext } = useCatalogContext();

  if (isLoadingImagesData) {
    return <Loading />;
  }

  if (!imagesData) {
    return null;
  }

  const { broken = 0, existing = 0, missing = 0 } = imagesData;

  const imageOverviewData = {
    labels: ["Products with image", "Products with invalid image/url", "Products missing an image"],
    datasets: [
      {
        label: "",
        data: [existing, broken, missing],
        backgroundColor: ["#3D7BF7", "#F8727D", "#FBBB3C"],
        borderColor: ["#3D7BF7", "#F8727D", "#FBBB3C"],
        borderWidth: 1,
      },
    ],
  };

  const onCardClick = () => {
    resetCatalogContext();
    const selectedCatalogValue = convertCatalogToSelectValue(warehouseCatalog);
    setSelectedCatalog(selectedCatalogValue);
    navigate(`${RoutesConfig.catalogs}?catalogs`);
  };

  return (
    <ProductOverviewBox
      imageOverviewData={imageOverviewData}
      title={warehouseCatalog.name}
      onCardClick={onCardClick}
    />
  );
};
