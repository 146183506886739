import * as Sentry from "@sentry/browser";
import { Component, PropsWithChildren } from "react";
import { NavigateFunction } from "react-router-dom";

import {
  FallbackErrorPage,
  ForbiddenErrorPage,
  NotFoundErrorPage,
  ServerErrorPage,
  UnauthorizedErrorPage,
} from "@web/ui";
import { isParsingError, isStorageError } from "@web/utils";

import { RoutesConfig } from "src/config/routes";
import { isApiError } from "src/domain/apiError";

const NetworkRelatedError = ({
  status,
  navigate,
}: {
  status: number;
  navigate: NavigateFunction;
}) => {
  if (status >= 500) {
    return <ServerErrorPage errorCode={status} />;
  }

  if (status === 401) {
    return <UnauthorizedErrorPage />;
  }

  if (status === 403) {
    return <ForbiddenErrorPage />;
  }

  if (status === 404) {
    return (
      <NotFoundErrorPage
        onButtonClick={() => navigate(RoutesConfig.home)}
        buttonText="Go To Overview"
      />
    );
  }

  // Fallback for unknown API errors
  return <FallbackErrorPage errorDescription={`${status}`} />;
};

type Props = {
  navigate: NavigateFunction;
  pathname: string;
};

type State = {
  hasError: boolean;
  error: unknown;
};

export class ErrorBoundary extends Component<PropsWithChildren<Props>, State> {
  state: State = { hasError: false, error: null };

  static getDerivedStateFromError(error: unknown) {
    return { hasError: true, error };
  }

  componentDidCatch(error: unknown) {
    Sentry.captureException(error);
  }

  componentDidUpdate(prevProps: Readonly<PropsWithChildren<Props>>) {
    if (prevProps.pathname !== this.props.pathname) {
      // Reset state whenever user navigates to other route
      this.setState({ hasError: false, error: null });
    }
  }

  render() {
    const error = this.state.error;
    const { navigate } = this.props;

    if (error) {
      console.error(error);
    }

    if (isApiError(error) || isStorageError(error)) {
      return <NetworkRelatedError status={error.status} navigate={navigate} />;
    }

    if (isParsingError(error)) {
      // We are handling parsing errors here
      return <FallbackErrorPage errorDescription="Data Parsing Error" error={error} />;
    }

    if (error) {
      // We are handling runtime errors here
      return <FallbackErrorPage errorDescription="Runtime Error" error={error} />;
    }

    return this.props.children;
  }
}
