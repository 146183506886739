import { ActiveElement, ArcElement, ChartEvent, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import classnames from "classnames";
import { ChartProps, Pie } from "react-chartjs-2";

import { Paragraph } from "@web/ui";

ChartJS.register(ArcElement, Tooltip, Legend);

type ProductOverviewBoxProps = {
  imageOverviewData: {
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      backgroundColor: string[];
      borderColor: string[];
      borderWidth: number;
    }[];
  };
  onChartClick?: (index: number) => void;
  title?: string;
  onCardClick?: () => void;
};

const dummyPieChart = <div className="w-[120px] h-[120px] bg-neutral_300 rounded-full" />;

export const ProductOverviewBox = ({
  imageOverviewData,
  onChartClick,
  title = "Image Overview",
  onCardClick,
}: ProductOverviewBoxProps) => {
  const totalImages = imageOverviewData.datasets[0].data.reduce((sum, count) => sum + count, 0);

  const existingPercentage = (imageOverviewData.datasets[0].data[0] / totalImages || 1) * 100;
  const brokenPercentage = (imageOverviewData.datasets[0].data[1] / totalImages || 1) * 100;
  const missingPercentage = (imageOverviewData.datasets[0].data[2] / totalImages || 1) * 100;

  const hasFullPercentage = [existingPercentage, brokenPercentage, missingPercentage].some(
    (percentage) => percentage === 100
  );

  const options: ChartProps<"pie">["options"] = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        boxWidth: 10,
        boxHeight: 10,
        padding: 10,
      },
    },
    events: ["click"] as Array<keyof HTMLElementEventMap>,
    onClick: (event: ChartEvent, elements: ActiveElement[]) => {
      if (elements.length > 0 && !!onChartClick) {
        const chartElement = elements[0];
        const index = chartElement.index;
        onChartClick(index);
      }
    },
  };

  const updatedImageOverviewData = {
    ...imageOverviewData,
    datasets: imageOverviewData.datasets.map((dataset) => ({
      ...dataset,
      borderColor: "white",
      borderWidth: hasFullPercentage ? 0 : 2,
    })),
  };

  const getPercentage = (index: number) => imageOverviewData.datasets[0].data[index];

  return (
    <div
      className={classnames(
        "bg-neutral_100 hover:bg-neutral_200 border border-neutral-100 p-5 max-w-[472px] h-[216px]",
        {
          "cursor-pointer": !!onCardClick,
        }
      )}
      onClick={onCardClick}
      data-testid="productOverviewBox"
    >
      <div className="flex mb-5">
        <Paragraph
          size="200"
          weight="heavy"
          className="text-ellipsis overflow-hidden whitespace-nowrap"
          title={title}
          data-testid="productOverviewBox_title"
        >
          {title}
        </Paragraph>
      </div>

      <div className="flex items-stretch">
        <div
          className="flex-shrink flex justify-center items-center w-[120px]"
          data-testid="productOverviewBox_imagesOverview"
        >
          {totalImages ? <Pie data={updatedImageOverviewData} options={options} /> : dummyPieChart}
        </div>

        <div className="pl-4 flex-1">
          <div
            className="flex items-center justify-between my-2"
            data-testid="productOverviewBox_totalProducts"
          >
            <Paragraph size="200" className="flex justify-around">
              Total products
            </Paragraph>
            <Paragraph size="200" className="flex justify-around" weight="heavy">
              {totalImages}
            </Paragraph>
          </div>

          {imageOverviewData.labels.map((label, index) => {
            return (
              <div key={index} className="flex justify-between mb-2 ">
                <div className="flex items-center">
                  <div
                    className="w-4 h-4 mr-2"
                    style={{
                      backgroundColor: imageOverviewData.datasets[0].backgroundColor[index],
                    }}
                  />
                  <Paragraph size="200" className="flex justify-around">
                    {label}
                  </Paragraph>
                </div>
                <Paragraph size="200" className="flex justify-around" weight="heavy">
                  {getPercentage(index)}
                </Paragraph>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
