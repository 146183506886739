import { XIcon } from "@heroicons/react/solid";
import { useAuth } from "oidc-react";
import { useEffect, useState } from "react";

import { ProductSearchResultItem } from "@web/sherlock";
import { AgreementDetailsResponse, ContractListResponseItem } from "@web/sopimus";
import { Heading, Label, Loading, OptionType, Paragraph, SearchBar, Select } from "@web/ui";
import { Srn } from "@web/wallstreet";
import { Catalog } from "@web/warehouse";

import { IntersectionMonitor } from "src/components/IntersectionMonitor/IntersectionMonitor";
import { Layout } from "src/components/Layout";
import { useCatalogContext } from "src/context/CatalogContext";

import { useAgreementsQuery, useCatalogsQuery, useContractsQuery, useImagesDataQuery } from "./api";
import { useAvailablePortsQuery } from "./api/useAvailablePortsQuery";
import { useInfiniteProductsQuery } from "./api/useProductsQuery";
import ImageOverviewBox from "./components/ImageOverviewBox";
import ProductsListTable from "./components/ProductsListTable";
import TotalProductsBox from "./components/TotalProductsBox";

export type ImageQualityFilterType = "EXISTING" | "MISSING" | "BROKEN" | "ALL";

export const CatalogsPage = () => {
  const auth = useAuth();
  const {
    selectedCatalog,
    setSelectedCatalog,
    selectedContract,
    setSelectedContract,
    selectedAgreement,
    setSelectedAgreement,
    locationCode,
    setLocationCode,
    selectedPort,
    setSelectedPort,
    productsList,
    setProductsList,
    showHint,
    setShowHint,
    searchInputValue,
    setSearchInputValue,
  } = useCatalogContext();
  const [shouldFetchProducts, setShouldFetchProducts] = useState<boolean>(false);
  const [isFetchingNextPage, setIsFetchingNextPage] = useState<boolean>(false);
  const [imageQualityFilter, setImageQualityFilter] = useState<ImageQualityFilterType>("ALL");
  const token = auth.userData?.access_token;
  const pageSize = 20;

  const {
    data: catalogsList,
    isPending: isCatalogsListPending,
    error: catalogsListError,
  } = useCatalogsQuery();

  const {
    data: contractsList,
    isPending: isContractsListPending,
    error: contractsListError,
  } = useContractsQuery(token || "");

  const {
    data: agreementsList,
    isPending: isAgreementsListPending,
    error: agreementsListError,
  } = useAgreementsQuery(token || "", selectedContract?.value || "", {
    enabled: selectedContract?.value !== "select-contract",
  });

  const {
    data: availablePortsList,
    isPending: isAvailablePortsListPending,
    error: availablePortsListError,
  } = useAvailablePortsQuery(
    token || "",
    selectedCatalog?.srn || "",
    selectedAgreement?.value || "",
    {
      enabled: selectedAgreement?.value !== "select-agreement",
    }
  );

  const {
    data: productsListData,
    isPending: isProductsListPending,
    fetchNextPage: fetchNextPage,
    hasNextPage: hasNextPage,
    isFetchingNextPage: isFetchingMore,
  } = useInfiniteProductsQuery(
    selectedCatalog?.value === "select-contract" ? "" : selectedCatalog?.value,
    pageSize,
    imageQualityFilter,
    selectedAgreement?.value === "select-agreement" ? "" : selectedAgreement?.value,
    locationCode === "select-port" ? "" : locationCode,
    searchInputValue || "",
    {
      enabled: shouldFetchProducts,
    }
  );

  const handleFetchProducts = () => {
    setShouldFetchProducts(false);
    setShowHint(false);
    setTimeout(() => {
      setShouldFetchProducts(true);
    }, 100);
  };

  const {
    data: imagesData,
    isPending: isImagesDataPending,
    error: imagesDataError,
  } = useImagesDataQuery(
    selectedCatalog?.value || "",
    selectedAgreement?.value || "",
    locationCode,
    {
      enabled: shouldFetchProducts,
    }
  );

  const convertCatalogs = (data: Catalog[]) => {
    if (data && data.length > 0 && catalogsListError === null) {
      return data.map((catalog) => ({
        value: catalog.catalogId,
        label: catalog.name,
        srn: catalog.srn,
      }));
    } else {
      return [{ value: "select-catalog", label: "Select catalog" }];
    }
  };

  const convertContracts = (data: ContractListResponseItem[]) => {
    if (data && data.length > 0 && contractsListError === null) {
      return data.map((contract) => ({
        value: contract.id,
        label: contract.name,
      }));
    } else {
      return [{ value: "select-contract", label: "Select contract" }];
    }
  };

  const convertAgreements = (data: AgreementDetailsResponse[]) => {
    if (data && data.length > 0 && agreementsListError === null) {
      return data.map((agreement) => ({
        value: agreement.srn,
        label: agreement.name,
      }));
    } else {
      return [{ value: "select-agreement", label: "Select agreement" }];
    }
  };

  const convertPorts = (data: Srn[]) => {
    if (data && data.length > 0 && availablePortsListError === null) {
      return data.map((port) => ({
        value: port.slice(-6),
        label: port.slice(-6),
      }));
    } else {
      return [{ value: "select-port", label: "Select port" }];
    }
  };

  const onSelectedCatalogChange = (value: OptionType) => {
    setSelectedCatalog(value);
    setSearchInputValue("");
    setShouldFetchProducts(false);
  };

  const onSelectedContractChange = (value: OptionType) => {
    setSelectedContract(value);
    setSearchInputValue("");
    setSelectedAgreement({
      value: "select-agreement",
      label: "Select agreement",
    });
    setShouldFetchProducts(false);
  };

  const onSelectedAgreementChange = (value: OptionType) => {
    setSelectedAgreement(value);
    setSearchInputValue("");
    setShouldFetchProducts(false);
    setSelectedPort({
      value: "select-port",
      label: "Select port",
    });
    setLocationCode("");
  };

  const onSelectedPortChange = (value: OptionType) => {
    setSelectedPort(value);
    setLocationCode(value.value);
  };

  useEffect(() => {
    if (productsListData?.pages && !isProductsListPending) {
      const allProducts = productsListData.pages.flatMap((page) => page.products);
      setProductsList(allProducts as ProductSearchResultItem[]);
    }
  }, [isProductsListPending, productsListData?.pages, setProductsList, imageQualityFilter]);

  useEffect(() => {
    if (selectedPort.value !== "select-port" || searchInputValue !== "") {
      setShouldFetchProducts(true);
      setShowHint(false);
    }
  }, [setShowHint, searchInputValue, selectedPort]);

  const existingImages = imagesData?.existing || 0;
  const brokenImages = imagesData?.broken || 0;
  const missingImages = imagesData?.missing || 0;

  const totalImages = existingImages + brokenImages + missingImages;
  const imageOverviewData = {
    labels: [
      `${existingImages} Showing image (${((existingImages / totalImages) * 100).toFixed(1)}%)`,
      `${brokenImages} Broken image (${((brokenImages / totalImages) * 100).toFixed(1)}%)`,
      `${missingImages} Image not provided (${((missingImages / totalImages) * 100).toFixed(1)}%)`,
    ],
    datasets: [
      {
        label: "",
        data: [existingImages, brokenImages, missingImages],
        backgroundColor: ["#3D7BF7", "#F8727D", "#FBBB3C"],
        borderColor: ["#3D7BF7", "#F8727D", "#FBBB3C"],
        borderWidth: 1,
      },
    ],
  };

  const isSearchEnabled =
    selectedPort?.value === "select-port" ||
    selectedContract?.value === "select-contract" ||
    selectedCatalog?.value === "select-catalog" ||
    selectedAgreement?.value === "select-agreement";

  return (
    <Layout>
      <div className="flex space-x-3 mb-5 items-center" data-testid="catalogsPage">
        <div className="w-[16vw] max-w-[250px]">
          <Label size="200" color="text-textIcon-blackPrimary">
            Supplier Catalog
          </Label>
          {!isCatalogsListPending || !catalogsListError ? (
            <Select
              data-testid="catalogsPage_supplierCatalogInput"
              placeholder="Select catalog"
              value={selectedCatalog}
              options={convertCatalogs(catalogsList as Catalog[]) as OptionType[]}
              onChange={onSelectedCatalogChange}
              disabled={isCatalogsListPending}
              className="z-50"
              dropdownHPosition="left-auto right-0"
            />
          ) : (
            <Loading />
          )}
        </div>
        <div className="w-[16vw] max-w-[250px]">
          <Label size="200" color="text-textIcon-blackPrimary">
            Contract
          </Label>
          {!isContractsListPending || !contractsListError ? (
            <Select
              data-testid="catalogsPage_contractInput"
              placeholder="Select contract"
              value={selectedContract}
              options={
                convertContracts(contractsList as ContractListResponseItem[]) as OptionType[]
              }
              onChange={onSelectedContractChange}
              disabled={isContractsListPending}
              className="z-50"
              dropdownHPosition="left-auto right-0"
            />
          ) : (
            <Loading />
          )}
        </div>
        <div className="w-[16vw] max-w-[250px]">
          <Label size="200" color="text-textIcon-blackPrimary">
            Price Agreement
          </Label>
          {!isAgreementsListPending || !agreementsListError ? (
            <Select
              data-testid="catalogsPage_agreementInput"
              placeholder="Select agreement"
              disabled={
                isAgreementsListPending ||
                selectedContract?.value === "select-contract" ||
                selectedCatalog?.value === "select-catalog"
              }
              value={selectedAgreement}
              options={
                convertAgreements(agreementsList as AgreementDetailsResponse[]) as OptionType[]
              }
              onChange={onSelectedAgreementChange}
              className="z-50"
              dropdownHPosition="left-auto right-0"
            />
          ) : (
            <Loading />
          )}
        </div>
        <div className="w-[150px]">
          <Label size="200" color="text-textIcon-blackPrimary">
            UN/LOCODE
          </Label>
          {!isAvailablePortsListPending || !availablePortsListError ? (
            <Select
              data-testid="catalogsPage_portInput"
              placeholder="Select port"
              disabled={
                isAvailablePortsListPending ||
                selectedContract?.value === "select-contract" ||
                selectedCatalog?.value === "select-catalog" ||
                selectedAgreement?.value === "select-agreement"
              }
              value={selectedPort}
              options={convertPorts(availablePortsList as Srn[]) as OptionType[]}
              onChange={onSelectedPortChange}
              className="z-50"
              dropdownHPosition="left-auto right-0"
            />
          ) : (
            <Loading />
          )}
        </div>
        <div className="w-[250px]">
          <Label size="200" color="text-textIcon-blackPrimary">
            Search
          </Label>
          <SearchBar
            data-testid="catalogsPage_searchInput"
            placeholder={isSearchEnabled ? "Select port to search" : "Search..."}
            onSubmit={(e) => {
              e.preventDefault();
            }}
            onQueryChange={(v) => setSearchInputValue(v.target.value)}
            query={searchInputValue}
            removeOuterPaddings
            disabled={isSearchEnabled}
          />
        </div>
      </div>
      <div className="z-0">
        <div>
          {!imagesDataError &&
            !isImagesDataPending &&
            searchInputValue === "" &&
            (selectedPort?.value !== "select-port" || shouldFetchProducts) && (
              <div className="flex space-x-4">
                <TotalProductsBox productsNumber={totalImages} />
                <ImageOverviewBox
                  imageOverviewData={imageOverviewData}
                  setImageQualityFilter={setImageQualityFilter}
                  imageQualityFilter={imageQualityFilter}
                  fetchProducts={() => handleFetchProducts()}
                  setShowHint={setShowHint}
                />
              </div>
            )}
          {imageQualityFilter !== "ALL" && (
            <div
              className="flex mb-5 bg-neutral_100 border border-neutral-300 pl-5 items-center py-1 w-[300px]"
              data-testid="catalogsPage_imageQualityFilter"
            >
              <Paragraph size="200">
                Active filter:{" "}
                <b>
                  {imageQualityFilter === "EXISTING"
                    ? "Showing image"
                    : imageQualityFilter === "MISSING"
                    ? "Image not provided"
                    : "Broken image"}
                </b>
              </Paragraph>
              <div
                data-testid="catalogsPage_imageQualityFilterXIcon"
                className="ml-auto pr-2 cursor-pointer"
                onClick={() => {
                  setImageQualityFilter("ALL");
                  handleFetchProducts();
                }}
              >
                <XIcon className="h-5 w-5 text-text-whiteDisabled" aria-hidden="true" />
              </div>
            </div>
          )}
          {!isProductsListPending && shouldFetchProducts && (
            <ProductsListTable
              catalogId={selectedCatalog?.value || ""}
              productsList={productsList}
              isLoading={isProductsListPending}
            />
          )}
          {isProductsListPending && shouldFetchProducts && <Loading />}
        </div>

        {showHint && (
          <div
            className="flex center items-center text-center w-full justify-center mt-20"
            data-testid="catalogsPage_hint"
          >
            <Heading size="300" color="text-textIcon-blackSecondary">
              Please select fields above to view products.
            </Heading>
          </div>
        )}

        {(isProductsListPending || isFetchingNextPage) &&
          locationCode.length === 5 &&
          selectedContract?.value !== "select-contract" &&
          selectedCatalog?.value !== "select-catalog" &&
          selectedAgreement?.value !== "select-agreement" && <Loading />}

        {hasNextPage && shouldFetchProducts && (
          <IntersectionMonitor
            onEnter={() => {
              fetchNextPage();
              setIsFetchingNextPage(true);
            }}
          >
            <div className="h-10">{isFetchingMore && <Loading />}</div>
          </IntersectionMonitor>
        )}
      </div>
    </Layout>
  );
};
