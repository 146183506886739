import { Label, SearchNavLink } from "@web/ui";

interface Props {
  text: string;
  pathname: string;
  search?: string;
  badge?: number;
}

export const BadgeLink: React.FC<Props> = ({ pathname, search, badge, text }) => {
  return (
    <SearchNavLink
      to={{ pathname, search }}
      activeClassName="bg-neutral_700"
      className="group flex flex-row w-full rounded-lg pt-2 pl-1 pb-2 mt-2 ml-2 hover:bg-neutral_800 text-textIcon-whitePrimary cursor-pointer"
    >
      {({ isActive }: { isActive: boolean }) => (
        <div className="flex justify-between w-full">
          <Label
            size="200"
            color={`${isActive ? "text-textIcon-whitePrimary" : ""}`}
            className={`pl-3 cursor-pointer my-auto`}
          >
            {text}
          </Label>
          {!!badge && badge !== 0 && (
            <Label
              size="300"
              color="text-neutral_0"
              className={`bg-dangerDefault rounded-xl px-4 py-1 float-right mr-3 ml-3 shadow-md group-hover:bg-dangerDefault cursor-pointer ${
                isActive && "bg-dangerDefault"
              }`}
            >
              {badge}
            </Label>
          )}
        </div>
      )}
    </SearchNavLink>
  );
};
