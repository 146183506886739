import { useUnleashContext } from "@unleash/proxy-client-react";
import { useAuth } from "oidc-react";
import { useEffect } from "react";

import { useFeatureFlagsManagement } from "@web/common";

export const UnleashContextSetup = () => {
  const user = useAuth();
  const { unleashContextFlagProperties } = useFeatureFlagsManagement();
  const updateContext = useUnleashContext();

  useEffect(() => {
    updateContext({
      userId: user?.userData?.profile.email,
      properties: unleashContextFlagProperties,
    });
  }, [user?.userData?.profile.email, unleashContextFlagProperties, updateContext]);

  return null;
};
