import classNames from "classnames";

interface Props {
  children: React.ReactNode;
  header: React.ReactNode;
  sidebar?: React.ReactNode;
  noPadding?: boolean;
  requisitionsWaitingForApproval?: number;
}

export const SidebarLayout: React.FC<Props> = ({ children, noPadding, sidebar, header }) => {
  return (
    <div data-testid="layout" className="min-h-screen flex flex-col">
      {header}
      <div className="flex flex-grow">
        {sidebar}
        <div
          className={classNames("flex-grow flex max-w-full ml-[300px]", {
            "flex-col": noPadding,
            "p-6 pl-8": !noPadding,
          })}
        >
          {noPadding ? (
            children
          ) : (
            <div className="mx-auto flex-grow max-w-full w-full flex flex-col">{children}</div>
          )}
        </div>
      </div>
    </div>
  );
};
