import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { ApiError, Catalog, CatalogsService } from "@web/warehouse";

export const WAREHOUSE_CATALOGS_QUERY_KEY_BASE = "warehouseCatalogs";

const queryKey = [WAREHOUSE_CATALOGS_QUERY_KEY_BASE];

type ResponseType = Catalog[];

export const useGetWarehouseCatalogsQuery = (hookOptions?: UseQueryOptions<ResponseType, ApiError>) =>
  useQuery<ResponseType, ApiError>({
    queryKey,
    queryFn: async () => {
      const response = await CatalogsService.list();
      return response;
    },
    ...hookOptions,
  });
